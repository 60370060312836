import React, { useMemo } from 'react'
import SnilsFormItem from '../../Common/FormItems/Snils/SnilsFormItem'
import { profileScreen } from '../../../translates'
import Documents from './Documents'
import { useStores } from '../../../Store/Store'
import { AppConfig } from '../../../AppConfig'
import { Divider } from 'antd'

function SnilsDetails ({
  form,
  trigger,
  handleUpdateImage = () => {},
  handleUploadingImage = () => {},
  handleDeleteImage = () => {},
  handleUpdateForm = () => {},
  filterDocuments = () => {},
  headerOrientation = 'left',
  size = 'large',
  hideDocs = false,
  hideBottomDivider = false,
  notRequired = false,
  showInputs = false
}) {
  const {
    partnerProfile
  } = useStores()

  const snilsDocumentList = useMemo(() => AppConfig.documentNameList
    .filter(d => [
      profileScreen.input.snilsPhoto.name
    ].includes(d)), [])

  return (
    <>
      <Divider orientation={headerOrientation}>СНИЛС</Divider>
      {showInputs && (
        <SnilsFormItem size={size} requiredRule={!notRequired} />
      )}
      {!hideDocs && (
        <Documents
          title={null}
          hidePhotoDoc
          documents={snilsDocumentList}
          trigger={trigger}
          form={form}
          required={partnerProfile?.required}
          filterDocuments={filterDocuments}
          onUpdate={handleUpdateImage}
          onUploading={handleUploadingImage}
          onDelete={handleDeleteImage}
          onPhotoDoc={handleUpdateForm}
        />
      )}
      {!hideBottomDivider && (<Divider />)}
    </>
  )
}

export default SnilsDetails
